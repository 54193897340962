import jQuery from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import SmoothScroll from 'smooth-scroll';
import magnificPopup from 'magnific-popup';

import Chartkick from "chartkick"
import Chart from "chart.js"

Chartkick.use(Chart)

window.$ = window.jQuery = jQuery;
window.bootstrap = bootstrap;
window.popper = popper;

// $('.video-pop-up').magnificPopup({
//   type: 'iframe',
//   mainClass: 'mfp-fade',
//   removalDelay: 160,
//   preloader: false,
//   fixedContentPos: false
// });

// $.magnificPopup.open({
//   items: {
//     src: 'survey.jpeg'
//   },
//   type: 'image',
//   image: {
//     markup: `
//       <div class="mfp-figure">
//         <button title="Close (Esc)" type="button" class="mfp-close">×</button>
//         <figure>
//           <a href="https://forms.gle/Y6nzTy75mFHH3nPR7" target="_blank">
//             <div class="mfp-img"></div>
//           </a>
//           <figcaption>
//             <div class="mfp-bottom-bar">
//               <div class="mfp-title"></div>
//               <div class="mfp-counter"></div>
//             </div>
//           </figcaption>
//         </figure>
//       </div>
//     `
//   }
// });

// $('.img-pop-up').magnificPopup({
//   type: 'image',
//   gallery: {
//       enabled: true
//   }
// });

$(window).on('scroll', function() {
  if ($(window).scrollTop() < 300) {
    $('.site-navigation').removeClass('sticky_header');
  } else {
    $('.site-navigation').addClass('sticky_header');
  }
});

var scroll = new SmoothScroll('a[data-scroll]');

$('.hamburger-menu').on('click', function() {
  $(this).toggleClass('open');
  $('.site-navigation').toggleClass('show');
});

var pathname = location.pathname.split("/");
console.log(pathname);
$('.site-navigation a[href="' + pathname[pathname.length - 1] + '"]').parent().addClass('current-menu-item');

function counter() {
  $('.count-number').each(function() {
    let countTo = $(this).attr("data-count");
    let $this = $(this);
    console.log(countTo);
    $({ countNum: $this.text() }).animate({
      countNum: countTo
    }, {
      duration: 8000,
      easing: 'linear',
      step: function() {
        $this.text(Math.floor(this.countNum))
      },
      complete: function() {
        $this.text(this.countNum)
      }
    })
  })
}

fetch("https://app.breakcorona.in/api/count")
  .then((resp) => resp.json()) // Transform the data into json
  .then(function(data) {

    $('.counter-item:nth-of-type(1) .count-number').attr('data-count', data.ideas)
    $('.counter-item:nth-of-type(2) .count-number').attr('data-count', data.solutions)
    counter();

  });

new Chartkick.BarChart("chart-idea", [
  ["Approved", 32],
  ["Play", 1492]
], { colors: ["#b00", "#666"] })